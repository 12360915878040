import React, {FC, useEffect, useState} from "react";
import {format, isValid, parseISO}      from "date-fns";
import {DateWheelPicker}                from "./DateWheelPicker";
import {
    autoUpdate, flip, FloatingFocusManager, offset, shift, useClick, useDismiss, useFloating, useInteractions, useRole
}                                       from "@floating-ui/react";
import Translator                       from "../Translator";
import {DatePickerHeader}               from "./DatePickerHeader";
import Modal                            from "react-responsive-modal";

type DateObject = {
    day: number | undefined,
    month: number | undefined,
    year: number | undefined
};

const defaultDateObject = {day: undefined, month: undefined, year: undefined};
const createDateObject  = (dateString: string) => {
    const date = parseISO(dateString);
    if (isValid(date)) {
        return {
            day:   date.getDate(),
            month: date.getMonth(),
            year:  date.getFullYear()
        };
    } else {
        return {...defaultDateObject};
    }
};

const ContainerHeight = 200;
const ItemHeight      = 45;
const PickerWidths    = {
    day:   60,
    month: 60,
    year:  80
};

type DatePickerProps = {
    label: string,
    name: string,
    value: string,
    onChange: (event: { target: { name: string, value: string } }) => void,
    dateFormat?: string,
    error: boolean,
    useModal?: boolean,
}

type DatePickerVariantProps = {
    buttonText: string,
    dateObject: DateObject,
    formControlClassName: string,
    isValid: boolean,
    handleOnChange: (dateObject: DateObject) => void,
}

type DatePickerComponentProps = {
    isValid: boolean,
    dateObject: DateObject,
    handleOnChange: () => void,
    handleOnConfirm: () => void,
}

const DatePickerComponent: FC<DatePickerComponentProps> = (props) => {
    const {
        isValid,
        dateObject,
        handleOnChange,
        handleOnConfirm
    } = props;


    // if date object is undefined, init with 2000-01-01

    if(dateObject.day === undefined) {
        dateObject.day = 1;
    }

    if(dateObject.month === undefined) {
        dateObject.month = 0;
    }

    if(dateObject.year === undefined) {
        dateObject.year = 2000;
    }

    return (<>
        <DatePickerHeader widths={PickerWidths} />
        <DateWheelPicker
            value={dateObject}
            focusDate={new Date()}
            onChange={handleOnChange}

            containerHeight={ContainerHeight}
            itemHeight={ItemHeight}
            widths={PickerWidths}
            updateOnScroll
        />
        <button
            type={'button'}
            className="btn btn-stayery-black btn-fullwidth m-0"
            onClick={handleOnConfirm}
        >
            {isValid ? (
                <Translator content={"buttons.confirm"} className={'datepicker-confirm-button'} />
            ) : (
                <Translator content={"buttons.close"} className={'datepicker-confirm-button'} />
            )}
        </button>
    </>);
}

const ModalDatePicker: FC<DatePickerVariantProps> = (props) => {
    const {
        buttonText,
        dateObject,
        formControlClassName,
        isValid,
        handleOnChange
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    return (<>
        <button
            type={'button'}
            className={formControlClassName}
            onClick={(): void => setIsOpen(true)}
        >
            {buttonText}
        </button>
        <Modal
            open={isOpen}
            onClose={(): void => setIsOpen(false)}
            center
            showCloseIcon={false}
            blockScroll={false}
        >
            <DatePickerComponent
                isValid={isValid}
                dateObject={dateObject}
                handleOnChange={handleOnChange}
                handleOnConfirm={(): void => setIsOpen(false)}
            />
        </Modal>
    </>);
}

const FloatingDatePicker: FC<DatePickerVariantProps> = (props) => {
    const {
        buttonText,
        dateObject,
        formControlClassName,
        isValid,
        handleOnChange
    } = props;

    const [isOpen, setIsOpen] = useState(false);

    const {
        refs,
        floatingStyles,
        context
    } = useFloating({
        open:                 isOpen,
        onOpenChange:         setIsOpen,
        middleware:           [offset(8), flip(), shift()],
        whileElementsMounted: autoUpdate,
        placement:            'bottom-start'
    });

    const click   = useClick(context);
    const dismiss = useDismiss(context);
    const role    = useRole(context);

    // Merge all the interactions into prop getters
    const {
        getReferenceProps,
        getFloatingProps
    } = useInteractions([click, dismiss, role]);

    return (<>
        <button
            type={'button'}
            className={formControlClassName}
            ref={refs.setReference}
            {...getReferenceProps()}
        >
            {buttonText}
        </button>
        {isOpen && (<FloatingFocusManager context={context}>
            <div
                className={'datepicker-popover'}
                ref={refs.setFloating}
                style={floatingStyles}
                {...getFloatingProps()}
            >
                <DatePickerComponent
                    isValid={isValid}
                    dateObject={dateObject}
                    handleOnChange={handleOnChange}
                    handleOnConfirm={(): void => setIsOpen(false)}
                />
            </div>
        </FloatingFocusManager>)}
    </>);
}

export const DatePicker: FC<DatePickerProps> = (props) => {
    const {
        label,
        name,
        value,
        onChange,
        dateFormat = 'yyyy-MM-dd',
        error,
        useModal = true
    } = props;

    const [dateObject, setDateObject] = useState(createDateObject(value));

    useEffect(() => {
        setDateObject(createDateObject(value));
    }, [value]);

    const hasValidValue = (value) => {
        return value.day !== undefined && value.month !== undefined && value.year !== undefined;
    }

    const toDate = (value) => {
        if (!hasValidValue(value)) {
            return new Date();
        }
        return new Date(value.year, value.month, value.day);
    }

    let formControlClassName = 'datepicker-button form-control';
    if (error) {
        formControlClassName += " is-invalid";
    }

    const buttonText = hasValidValue(dateObject) ? format(toDate(dateObject), 'dd.MM.yyyy') : '';

    const handleOnChange = (newValue: DateObject) => {
        setDateObject(newValue);

        if (hasValidValue(newValue)) {
            const date = toDate(newValue);
            if (isValid(date)) {
                const dateString = format(date, dateFormat)
                const event      = {
                    target: {
                        name:  name,
                        value: dateString
                    }
                };
                onChange(event);
            }
        }
    };

    const variantProps: DatePickerVariantProps = {
        buttonText,
        dateObject,
        formControlClassName,
        isValid: hasValidValue(dateObject),
        handleOnChange,
    }

    return (
        <div className="form-group">
            <label className="control-label" style={{"whiteSpace": "nowrap"}}>
                <Translator content={label} />
            </label>
            {useModal
                ? <ModalDatePicker {...variantProps} />
                : <FloatingDatePicker {...variantProps} />
            }
        </div>
    );
}
